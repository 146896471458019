<template>
    <client-page>
        <page-section-primary class="page-section--first page-section--last">
            <template #tit>이용약관</template>

            <terms-component code="terms"></terms-component>
        </page-section-primary>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";


export default {
    components: {
        ClientPage,
        PageSectionPrimary,
        TermsComponent,
    },
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
};
</script>

<style lang="scss" scoped></style>

