var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("개인정보처리방침")];
      },
      proxy: true
    }])
  }, [_c('terms-component', {
    attrs: {
      "code": "privacy"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }